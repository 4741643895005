<template>
  <div>
    <window-header></window-header>
    <nav-form>
      <li slot="reports" @click="printTarjeta">Tarjeta de coordenadas</li>
      <template v-slot:actions>
        <li v-if="itemId" @click="configurar2FA">
          Configurar 2FA
        </li>
      </template>
    </nav-form>
    <section>
      <article style="height:300px; width:644px">
        <r-tabs ref="main-tabs" :wt="wt" style="top:0px;height:298px;" :buttons="[
          'Público',
          'Privado'
        ]">
          <div class="tab">
            <field name="nombre" widget="char" label="Nombre" help="Nombre" inline-label placeholder="Nombre" searchable
              width="260px" style="top:47px; left:10px;" title="Nombre" />
            <field name="telefono" widget="char" label="Telefono" inline-label placeholder="Telefono" searchable
              width="154px" style="top:87px; left:6px;" />
            <field name="email" widget="char" label="E-Mail" inline-label placeholder="e-mail" searchable width="220px"
              style="top:127px; left:21px;"
              :class="{ wrong: formData.email && !$utils.validation.checkEmail(formData.email) }" />
          </div>
          <div class="tab">
            <field name="login" widget="char" label="Login" help="Login" inline-label placeholder="Login" searchable
              width="200px" style="top:47px; right:10px;" />
            <field name="password" widget="char" label="Password" type="password" inline-label placeholder="Password"
              searchable width="154px" style="top:87px; right:10px;" />
            <field v-if="wt" name="password_a" widget="char" label="Password (A)" type="password" inline-label
              placeholder="Password" searchable width="154px" style="top:115px; right:10px;" />
            <field name="grupo_id" widget="m2o" label="Grupo" labelProp="descripcion" idProp="codigo"
              help="Grupo Asociado" inline-label searchable width="180px" style="top:150px; right:10px;" />
            <field name="vacaciones" widget="char" label="Nº Vacaciones Disponibles" inline-label searchable
              width="30px" style="top:180px; right:10px;" />
            <field name="fecha_tarjeta" type="date" label="Fecha Tarjeta" inline-label readonly width="100px"
              style="top:210px; right:10px;" />
            <div style="margin:10px;">
              <span>Tarjeta de coordenadas</span>
              <button v-if="mode == 'edit' || mode == 'new'" class="generar_tj button width-100"
                @click="generarTarjeta">
                <i class="fa fa-refresh fa-1x"></i> Generar nueva
              </button>
            </div>
            <table class="tabla_tj">
              <tr>
                <th></th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>E</th>
                <th>F</th>
                <th>G</th>
                <th>H</th>
              </tr>
              <tr v-for="(fila, n) in parsedTarjeta" :key="'tarjeta-' + n">
                <th>{{ n }}</th>
                <td v-for="(val, letra) in fila" :key="'tarjeta' + n + '-' + letra">{{ val }}</td>
              </tr>
            </table>
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
function aleatorio(minimo, maximo) {
  var rang = maximo - minimo; // obtenemos el divisor a utilizar
  var faleat = Math.floor(Math.random() * 1000); //obtenemos un número entero aleatorio del 0 al 999
  var aleat = faleat % rang; // obtenemos su módulo (resto entero de la división) por el divisor
  return aleat + minimo; //le añadimos el mínimo para que esté dentro del rango solicitado
}

import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    return {
      title: "Usuarios",
      dbAdapter: "usuario",
      primary: "id",
      fields: ["tarjeta"],
      wt: 0
    };
  },
  computed: {
    parsedTarjeta() {
      try {
        return JSON.parse(this.formData.tarjeta);
      } catch (e) {
        let tarjeta = {};
        for (let j = 1; j <= 9; j++) {
          tarjeta[j] = {};
          ["A", "B", "C", "D", "E", "F", "G", "H"].forEach(function (letra) {
            tarjeta[j][letra] = "";
          });
        }
        return tarjeta;
      }
    }
  },
  methods: {
    generarTarjeta() {
      var self = this;
      if (self.mode != "edit" && self.mode != "new") return;
      let tarjeta = {};
      for (let j = 1; j <= 9; j++) {
        tarjeta[j] = {};
        ["A", "B", "C", "D", "E", "F", "G", "H"].forEach(function (letra) {
          tarjeta[j][letra] = aleatorio(0, 999);
        });
      }
      self.formData.tarjeta = JSON.stringify(tarjeta);
      self.formData.fecha_tarjeta = new Date().yyyymmdd();
      self.$forceUpdate();
    },
    printTarjeta() {
      window.open(
        window.DB.server +
        "usuario/?action=printTarjeta&id=" +
        this.itemId +
        "&token=" +
        this.app.session.token,
        "_blank"
      );
    },
    configurar2FA() {
      let self = this;
      self.app.openChildWindow("usuario_2fa", self.win, {
        backdrop: true,
        selectable: true,
        model: self.dbAdapter,
        props: {
          initialMode: 'empty',
          initialId: self.itemId,
          //initialFormData: res.count ? null : initialFormData,
          defaultMode: 'edit',
          onLoad: function () {
            let self = this;
            //self.formData = { ...self.formData, ...{ password2: d.password, login: d.email, activo_web: 1 } }

          },
          onSave: (_, itemId) => {
          }
        }
      });
    }
  },
  mounted() {
    var self = this;
    self.$refs["main-tabs"].$refs.toolbar.addEventListener("dblclick", function (
      e
    ) {
      if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
        if (self.mode != "edit" && self.mode != "new") return;
        self.wt = !parseInt(self.wt) ? 1 : 0;
      }
    });
  }
};
</script>
<style>
.tabla_tj {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 5px;
  line-height: 1.25;
}

.tabla_tj td,
.tabla_tj th {
  width: 2.8em;
  font-size: 0.9em;
}

table.tabla_tj tr:nth-child(odd) {
  background-color: #f1f1f1;
}
</style>
